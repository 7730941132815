<template>
  <div>
    <div
      v-if="isLoadingComplete"
      class="d-flex align-items-center"
    >
      <strong>Loading...</strong>
      <b-spinner class="ml-auto" />
    </div>
    <table-group
      v-show="!showForm"
      :groups="tableData"
      @addGroup="addGroup($event)"
      @editGroup="editGroup($event)"
      @deleteGroup="confirmDeleteGroup($event)"
    />
    <form-group
      v-show="showForm"
      :action="action"
      @closeForm="closeForm"
      @actionSave="actionSave"
    />
  </div>
</template>

<script>

import { BSpinner } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { mapActions, mapMutations, mapState } from 'vuex'

import TableGroup from '@/components/catalogs/group/TableGroup.vue'
import FormGroup from '@/components/catalogs/group/FormGroup.vue'

export default {
  components: {
    BSpinner,
    TableGroup,
    FormGroup,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      showForm: false,
      action: '',
      searchTerm: '',
    }
  },

  computed: {
    ...mapState('group', ['groups', 'isLoading']),
    tableData() {
      return this.groups
    },
    isLoadingComplete() {
      return this.isLoading
    },
  },

  mounted() {
    this.searchGroup()
  },

  methods: {
    ...mapActions('group',
      ['searchGroup', 'createGroup', 'deleteGroup', 'updateGroup', 'restoreGroup']),
    ...mapMutations('group',
      { setGroup: 'setGroup' }),
    addGroup() {
      this.showForm = true
      this.restoreGroup()
      this.action = 'Create'
    },
    editGroup(group) {
      this.setGroup(group)
      this.showForm = true
      this.action = 'Edit'
    },
    closeForm() {
      this.showForm = false
    },
    actionSave(group) {
      if (this.action === 'Edit') {
        this.updateGroup(group)
      }
      if (this.action === 'Create') {
        this.createGroup(group)
      }
      this.showForm = false
    },
    async confirmDeleteGroup(group) {
      const response = await this.$swal({
        title: `Are you sure to delete the group ${group.name}.?`,
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn Are you sure',
        },
      })
      if (response && response.value) {
        this.deleteGroup(group)
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
